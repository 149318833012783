<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th v-if="showDate">
            Date
          </th>
          <th>Time</th>
          <th>Aircraft</th>
          <th v-if="showCaptain">
            Captain
          </th>
          <th
            v-if="showPOB"
            title="Persons on Board"
          >
            PoB
          </th>
          <th>Origin</th>
          <th v-if="showStatus">
            Status
          </th>
          <th
            v-if="showPaymentStatus"
            class="text-right"
          >
            <v-icon small>
              mdi-cash-multiple
            </v-icon>
          </th>
          <th
            v-if="showActions"
            class="text-right"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody v-if="items.length">
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td v-if="showDate">
            {{ item.date | formatDate }}
          </td>
          <td>{{ item.time }}</td>
          <td>
            <aircraft-info-popup
              :item="item"
              :show-info-icon="showInfoIcons"
            />
          </td>
          <td v-if="showCaptain">
            <pilot-info-popup
              :item="item"
              :show-info-icon="showInfoIcons"
            >
              {{ item.captain_name }}
            </pilot-info-popup>
          </td>
          <td v-if="showPOB">
            <pilot-info-popup
              :item="item"
              :show-info-icon="false"
            >
              {{ item.pob }}
            </pilot-info-popup>
          </td>
          <td>
            {{ item.origin }}
            <small v-if="item.origin_data">({{ item.origin_data.code }})</small>
          </td>
          <td v-if="showStatus">
            <status-info-popup
              :item="item"
              :show-info-icon="showInfoIcons"
            />
          </td>
          <td
            v-if="showPaymentStatus"
            class="text-right"
          >
            <v-tooltip
              v-if="item.payment_status === 'paid'"
              bottom
              color="teal"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="teal"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-credit-card-check
                </v-icon>
              </template>
              <span>
                Paid
                <v-icon
                  small
                  class="align-baseline"
                >
                  mdi-check
                </v-icon>
              </span>
            </v-tooltip>
          </td>
          <td
            v-if="showActions"
            class="text-right text-no-wrap"
          >
            <v-btn
              v-if="item.status === 'New'"
              class="ml-1"
              color="primary"
              fab
              x-small
              elevation="0"
              title="Approve Arrival"
              @click="approve(item)"
            >
              <v-icon>mdi-thumb-up</v-icon>
            </v-btn>
            <v-btn
              v-if="item.status === 'New'"
              class="ml-1"
              color="primary"
              fab
              x-small
              elevation="0"
              title="Reject Arrival"
              @click.stop="
                rejectDialogItem = item;
                rejectDialog = true;
              "
            >
              <v-icon>mdi-thumb-down</v-icon>
            </v-btn>
            <v-btn
              v-if="item.status === 'Approved'"
              class="ml-1"
              color="primary"
              fab
              x-small
              elevation="0"
              title="Mark as Arrived"
              @click="markAsArrived(item)"
            >
              <v-icon>mdi-airplane-landing</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="">
          <td
            colspan="99"
            class="text-body-2 text-center py-8"
          >
            No arrivals found.
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog
      v-model="rejectDialog"
      max-width="420"
    >
      <validation-observer ref="observer">
        <v-card>
          <v-card-title
            class="text-h5"
            v-if="rejectDialogItem"
          >
            Reject PPR from {{ rejectDialogItem.aircraft_reg }}?
          </v-card-title>

          <v-card-text>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <v-textarea
                label="Reason / Message"
                auto-grow
                required
                persistent-hint
                hint="Required, please give as much detail as possible for the reason."
                v-model="rejectDialogMessage"
                :error-messages="errors"
              />
            </validation-provider>
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="grey"
              text
              @click="rejectDialog = false"
              :disabled="rejectDialogIsSubmitting"
            >
              Cancel
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="reject(rejectDialogItem)"
              :disabled="rejectDialogIsSubmitting"
            >
              Reject
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from "vee-validate";
import { DateTime } from "luxon";
import AircraftInfoPopup from './AircraftInfoPopup.vue';
import PilotInfoPopup from './PilotInfoPopup.vue';
import StatusInfoPopup from './StatusInfoPopup.vue';

export default {
  name: "ArrivalsList",
  props: {
    items: {
      type: Array,
      required: true
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showCaptain: {
      type: Boolean,
      default: true
    },
    showPOB: {
      type: Boolean,
      default: true
    },
    showStatus: {
      type: Boolean,
      default: true
    },
    showPaymentStatus: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    showInfoIcons: {
      type: Boolean,
      default: false
    },
  },

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    AircraftInfoPopup,
    PilotInfoPopup,
    StatusInfoPopup,
  },

  data() {
    return {
      rejectDialog: false,
      rejectDialogItem: null,
      rejectDialogMessage: "",
      rejectDialogIsSubmitting: false
    };
  },

  methods: {
    approve: async function(item) {
      await this.$store.dispatch("arrivals/markAs", {
        status: "Approved",
        itemId: item.id
      });
    },
    reject: async function(item) {
      this.rejectDialogIsSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            await this.$store.dispatch("arrivals/markAsRejected", {
              itemId: item.id,
              rejectionMessage: this.rejectDialogMessage
            });

            this.rejectDialog = false;
            this.rejectDialogItem = null;
            this.rejectDialogMessage = "";
          }
        })
        .finally(() => {
          this.rejectDialogIsSubmitting = false;
        });
    },
    markAsArrived: async function(item) {
      await this.$store.dispatch("arrivals/markAs", {
        status: "Arrived",
        itemId: item.id
      });
    }
  },

  filters: {
    formatDate: function(value) {
      if (!value) return "";
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
    }
  }
};
</script>
